export const productGallery = {
  maximizeIconSize: '1rem',
  maxSwiperSlidesOnFullscreenQuickShop: 6,
  presets: {},
  thumbnailsMobileEnabled: false,
  quickShopNavigationVisible: true,
  quickShopSwiperDirection: 'vertical',
  quickShopThumbnailSpaceBetween: 16,
  thumbnailWidth: 100,
  thumbnailHeight: 128,
  chevronSize: '32px',
  usePresets: false,
  swiperControlsClass: '',
  showCustomsLogo: false,
  dotsNavigationEnabled: false,
  arrowsNavigationEnabled: false,
  scrollToError: true,
  closeButtonVisible: true,
};

































import { PropType, defineComponent, computed } from '@vue/composition-api';
import {
  QuickShopTileTranslations,
  QuickShopTileErrorMessage,
} from '@vf/api-contract';
import { ProductAttributeCodes } from '@vf/api-client';
import QuickShopTileOptionsSlot from './Molecule.QuickShopTileOptionsSlot.vue';

export default defineComponent({
  name: 'VfQuickShopTileOptions',
  components: {
    QuickShopTileOptionsSlot,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    translations: {
      type: Object as PropType<QuickShopTileTranslations>,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    colorsCount: {
      type: Number,
      default: 3,
    },
    errorMessage: {
      type: Object as PropType<QuickShopTileErrorMessage>,
    },
    showSizes: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const handleChange = (optionName, selected) => {
      emit('change', optionName, selected);
    };

    const extractAttribute = (product, attributeName) => {
      return (product?.attributes ?? []).find(
        (attribute) => attribute.code === attributeName
      );
    };

    const extractAllOptions = (attributeCode) => {
      return extractAttribute(props.product, attributeCode)?.options || [];
    };

    const extractAvailableVariants = (attributeCode) => {
      return props.product?.availableAttributes?.[attributeCode] ?? [];
    };

    const composeTitle = (attributeCode) => {
      const placeholder =
        props.translations.placeholders[`${attributeCode}Placeholder`];
      const options = extractAllOptions(attributeCode);
      const attributeOpt = options.find(
        (item) => item.value === props.value[attributeCode]
      );
      return attributeOpt
        ? `${placeholder} - ${attributeOpt.label || props.value[attributeCode]}`
        : placeholder;
    };

    const secondaryVariants = computed(() => {
      if (!props.product?.attributes) return [];
      return props.product.attributes.flatMap((attr) => {
        if (
          attr.code != ProductAttributeCodes.Color &&
          extractAllOptions(attr.code)?.length
        ) {
          if (
            (attr.code === ProductAttributeCodes.Size && !props.showSizes) ||
            attr.code === ProductAttributeCodes.Fit
          )
            return [];
          return {
            role: attr.code,
            options: extractAllOptions(attr.code),
            title: composeTitle(attr.code),
            titleGroup:
              props.translations.placeholders[`${attr.code}Placeholder`],
            value: props.value[attr.code],
            product: props.product,
            'available-variants': extractAvailableVariants(attr.code),
            'error-message': props.errorMessage,
          };
        } else return [];
      });
    });

    const colorsData = computed(() => {
      const colors = extractAttribute(props.product, 'color');

      return {
        children: (colors?.options ?? []).filter((color) => color.available),
      };
    });

    return {
      handleChange,
      colorsData,
      secondaryVariants,
      composeTitle,
    };
  },
});

export const reviews = {
  showMostLikedSection: false,
  isColorVariantId: true,
  isTrimProductIdAmount: false,
  showSubmittedByBeforeDesc: true,
  useWriteAReviewButton: true,
  showPaginationSeperator: true,
  showPaginationIcons: true,
  paginationIconPrevious: 'arrow_left',
  paginationIconNext: 'arrow_right',
  showStarsIndicator: false,
  useExpandedReviewsPanel: true,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfRadio',
  directives: {
    focus,
  },
  model: {
    prop: 'selected',
    event: 'input',
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    autofocus: Boolean,
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data: function () {
    return {
      showTooltip: false,
      isFocusable: true,
    };
  },
  computed: {
    isChecked() {
      return this.value === this.selected;
    },
    ariaLabel() {
      return this.label || `${this.name} ${this.value}`;
    },
  },
  methods: {
    inputHandler() {
      this.$emit('input', this.value);
    },
    focusOnRealInput() {
      document.getElementById(this._uid).focus();
    },
  },
});


























































































































import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { VueMaskDirective } from 'v-mask';
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from '@vue/composition-api';
import {
  BasicInformationObject,
  BasicInformationTranslations,
  BasicInformationValidationMessages,
  FormValidationConfig,
} from '@vf/api-contract';
import {
  getDatePlaceholderFor,
  getDateMaskFor,
  translateFromApiDateFormat,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import {
  getCacheKeyFromProps,
  checkName,
  checkDate,
  checkAge,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'BasicInformation',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<BasicInformationTranslations>,
      required: true,
    },
    basicInformation: {
      type: Object as PropType<BasicInformationObject>,
      default: () => ({}),
    },
    validationConfig: {
      type: Object as PropType<FormValidationConfig>,
      default: () => ({}),
    },
    validationMessages: {
      type: Object as PropType<BasicInformationValidationMessages>,
      default: () => ({}),
    },
    minRegisterAge: {
      type: Number,
      default: 13,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    subscribedToLoyalty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const dateMask = getDateMaskFor(props.locale);
    const datePlaceholder = computed(() =>
      getDatePlaceholderFor(props.locale).toUpperCase()
    );

    const birthdayRegex = computed(
      () => props.validationConfig?.birthdayRegex || `[\\d*]`
    );

    const hasBirthDateSet = computed(() =>
      Boolean(props.basicInformation.birthDate)
    );

    return {
      dateMask,
      datePlaceholder,
      birthdayRegex,
      hasBirthDateSet,
      getBirthdateMessage: (validationObj) => {
        if (!validationObj.$error) return;
        if (!validationObj.validFormat) {
          return props.validationMessages.birthdayError.replace(
            '{{dateFormat}}',
            datePlaceholder.value
          );
        }
        if (!validationObj.minAge) {
          return props.validationMessages.minRegisterAgeError.replace(
            '{{minRegisterAge}}',
            String(props.minRegisterAge)
          );
        }
      },
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      email: null,
      firstName: null,
      lastName: null,
      gender: null,
      birthDate: null,
    };
  },
  watch: {
    basicInformation: {
      handler() {
        this.copyBasicInformation();
      },
      immediate: true,
    },
  },
  methods: {
    copyBasicInformation() {
      this.email = this.basicInformation.email;
      this.firstName = this.basicInformation.firstName;
      this.lastName = this.basicInformation.lastName;
      this.birthDate = translateFromApiDateFormat(
        this.basicInformation.birthDate,
        this.locale
      );
      this.gender = this.basicInformation.gender;
    },
    saveInfo() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('save', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        ...(!this.hasBirthDateSet && {
          birthDate: translateToApiDateFormat(this.birthDate, this.locale),
        }),
      });
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      firstName: {
        required,
        validFormat: checkName(),
      },
      lastName: {
        required,
        validFormat: checkName(),
      },
      birthDate: {
        validFormat: checkDate(this.locale),
        minAge: checkAge(this.minRegisterAge, this.locale),
      },
    };
  },
});

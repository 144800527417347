export const payPal = {
  checkout: {
    color: 'gold',
    shape: 'pill',
    height: {
      base: 54,
    },
  },
  skipShippingAddressChecking: false,
};

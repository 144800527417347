export const AddressForm = {
  buttonBackToAddressBookClass: 'vf-button--text form__back_button',
  buttonBackToAddressBookShowIcon: true,
  buttonBackToAddressBookIconName: 'chevron_left',
  buttonSubmitClass: 'vf-button--primary form__action-button',
  buttonCancelClass: 'vf-button--tertiary form__action-button',
  markFieldAsRequired: true,
  scrollFormToError: true,
  fieldsOrder: [
    'country',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'province',
    'postalCode',
    'phone',
    'email',
  ],
};

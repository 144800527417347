









































































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { PropType } from 'vue';
import { inject } from '@vue/composition-api';

export default defineComponent({
  name: 'VfTextArea',
  directives: { focus },
  inheritAttrs: false,
  props: {
    /**
     * Current input value (`v-model`)
     */
    value: {
      type: [String, Number],
      default: null,
    },
    /**
     * Form input label
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Form input name
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: true,
    },
    /**
     * Error message value of form input. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: null,
    },
    /**
     * Helper text value of form input. It will be appeared if `valid` is `true`.
     */
    helperText: {
      type: String,
      default: null,
    },
    /**
     * Native input required attribute
     */
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute',
    },
    /**
     * Native input disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute',
    },
    /**
     * Status of show validation icons
     */
    validationIconsVisibility: {
      type: Boolean,
      default: true,
    },
    /**
     * Max text length
     */
    maxlength: {
      type: Number,
      default: 400,
    },
    /**
     * Number of TextArea Rows
     */
    rows: {
      type: String,
      default: '3',
    },
    /**
     * Number of TextArea Columns
     */
    cols: {
      type: String,
      default: '100',
    },
    /**
     * Resize css property
     */
    resize: {
      type: String as PropType<'vertical' | 'horizontal' | 'both' | 'none'>,
      default: '',
    },
  },
  setup(props) {
    const showValidationIcon: Ref<boolean> = ref(false);
    // TODO: GLOBAL15-62797 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      showValidationIcon,
      showSuccessIcon: computed(
        () => props.value && props.valid && showValidationIcon.value
      ),
      isCoreRedesignEnabled,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value),
      };
    },
  },
});

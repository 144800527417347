export const orderSuccess = {
  summaryHeadingTitleModifier: 'title-5',
  summaryHeadingLevelModifier: 5,
  pickupDetailsAtTop: true,
  showGiftOption: true,
  getDirectionButtonClass: 'vf-button--text vf-order-summary__direction-button',
  getDirectionButtonIcon: 'locationpin',
  showDeliveryDataInOneRow: true,
  pickupAddressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{city}}, {{province}} {{postalCode}}',
  ],
  pickupPersonInfoFormat: ['{{firstName}} {{lastName}}', '{{email}}'],
  altPickupPersonInfoFormat: [
    '{{altFirstName}} {{altLastName}}',
    '{{altEmail}}',
  ],
  personInfoFormat: ['{{firstName}} {{lastName}}', '{{phone}}', '{{email}}'],
  addressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{addressLine3}}',
    '{{city}}, {{province}} {{postalCode}}',
    '{{country}}',
  ],
};

export const dataListingTable = {
  addItemButtonClass:
    'vf-button--primary vf-button--large vf-button--mobile-full add-button--margin-2xl',
  headingWithoutSubtitle: true,
  tableClass: 'dl-table dl-table--small-spacing dl-table--vertical-margins',
  firstColumnTextAttributes: {
    fontSizes: ['sm'],
  },
  firstColumnSmallBottomMargin: true,
  baseColumnTextAttributes: {
    fontSizes: ['lg'],
  },
  actionColumnButtonClass: 'vf-radio__button--weight-normal',
  actionColumnClass: 'dl-table-column--action',
  actionColumnAttributes: {},
  headingAttributes: {
    titleModifier: 'title-5',
  },
  iconSize: {
    width: 40,
    height: 30,
  },
};

export const orderCard = {
  attributesOrder: ['SIZE', 'COLOR'],
  showProductQuantities: true,
  showNotificationCloseButton: true,
  showCosts: false,
  showDividerAfterProduct: true,
  showDifferentProductDescriptions: true,
  orderNotificationIcon: 'success',
  viewDetailsButtonClass: 'vf-button--medium',
  orderDetailsButtonClass: 'vf-button--fullWidth',
  propertyClass: '',
  productCardSize: {
    small: 12,
    medium: 4,
    large: 4,
  },
  imageSizes: {
    small: {
      width: 344,
      height: 400,
      quality: 85,
    },
    medium: {
      width: 344,
      height: 400,
      quality: 85,
    },
    large: {
      width: 344,
      height: 400,
      quality: 85,
    },
  },
};

export const searchSuggestions = {
  imageSizes: {
    small: {
      width: 140,
      height: 164,
    },
    medium: {
      width: 76,
      height: 89,
    },
    large: {
      width: 137,
      height: 160,
    },
  },
  showTopSearchesHeading: true,
  markQueryInResultLabels: true,
  showSeeMoreButtonInResults: false,
  allowResultsOverflow: true,
  seeMoreButtonVariant: 'border',
  showSearchSuggestionsPhrase: true,
  showSearchSuggestionsCloseButton: true,
  showMoreButton: true,
};

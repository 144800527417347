import type { Price, PriceRangeObject } from '@vf/api-contract';

export const getUpSellPriceFormatted = (
  price: Price,
  formatPrice: (price: number | string, currency?: string) => string
): {
  regularPrice: string | null;
  specialPrice: string | null;
  priceRange: PriceRangeObject | null;
} => {
  let regularPrice: string | null = null;
  let specialPrice: string | null = null;
  let priceRange: PriceRangeObject | null = null;

  if (
    price.salePriceRange &&
    price.salePriceRange.maxPrice !== price.salePriceRange.minPrice
  ) {
    priceRange = {
      lowest: price.salePriceRange.minPrice,
      highest: price.salePriceRange.maxPrice,
      currency: price.currency,
      isLowestPriceDiscounted:
        price.salePriceRange.minPrice !== price.basePriceRange.minPrice,
      isHighestPriceDiscounted:
        price.salePriceRange.maxPrice !== price.basePriceRange.maxPrice,
    };
  } else {
    specialPrice =
      price.original !== price.current
        ? formatPrice(price.current, price.currency)
        : null;
    regularPrice = formatPrice(price.original, price.currency);
  }

  return { regularPrice, specialPrice, priceRange };
};

export const header = {
  logoWidth: {
    small: 73,
    medium: 97, // 6.0625rem
    large: 97, // 6.0625rem
  },
  logoHeight: {
    small: 34,
    medium: 45, // '2.8125rem'
    large: 45, // '2.8125rem'
  },
  headerBackground: {
    variantPrimary: {
      start: 'rgba(255,255,255,0.8)',
      end: 'rgba(255,255,255,0)',
      backgroundColor: '#fff',
    },
    variantSecondary: {
      start: 'rgba(0,0,0,0.8)',
      end: 'rgba(0,0,0,0)',
      backgroundColor: '#000000',
    },
  },
  hasHeaderStyleVariants: true,
  checkoutTooltipModifier: 'primary',
  checkoutTooltipIcon: 'checkCircle',
  checkoutTooltipIconColor: '#4c842a',
  checkoutTooltipButtonClass: 'vf-button',
  searchBarHasClickOutside: true,
  isMobileMenuWithAccordion: true,
  hideShopAllText: true,
  disableBodyScrollInMenu: true,
  showClearButtonInMobileSearch: false,
  boxShadow: true,
  stickyHeader: false,
  isMobileSearchVisibleIcon: true,
  showEmptySearchError: false,
  minUtilityNavHeight: 36,
  mobileIconSize: 'lg',
  closeIconName: 'close',
  accordionIconType: 'arrow',
  minicartIconName: 'bag',
  alwaysShowPromoBarMobile: true,
};

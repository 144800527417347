var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"filter-dropdown",on:{"keydown":function($event){return _vm.onKeyDown($event)},"focusin":function($event){return _vm.onFocusIn($event)},"focusout":function($event){return _vm.onFocusOut($event)}}},[_c('VfButton',{ref:"toggleButton",staticClass:"vf-button--tiny vf-button--brick vf-button--icon-default filter-dropdown__button",class:{
      'vf-button--active': _vm.isOpen,
      'filter-dropdown__button--reversed': _vm.reversedButtonColor,
      'filter-dropdown__button--redesign': _vm.isFiltersUiUpdateEnabled,
      'filter-dropdown__button--reversed-redesign':
        _vm.isFiltersUiUpdateEnabled && _vm.reversedButtonColor,
    },attrs:{"icon":"chevron_down","aria-expanded":_vm.isOpen ? 'true' : 'false',"role":"button","aria-label":_vm.label},on:{"click":_vm.toggle}},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"refFilterWrapper",staticClass:"filter-dropdown__wrapper filters-wrapper",class:{ 'filter-dropdown__wrapper-right': _vm.wrapperRightPosition }},[_c('div',{ref:"refFilterContainer",staticClass:"filter-dropdown__content",class:'filter-dropdown__content-' + _vm.flow,style:({
          '--vf-filter-dropdown__content-col-number': _vm.colNumber,
          '--vf-filter-dropdown__content-max-height': _vm.maxHeight
            ? (_vm.maxHeight + "rem")
            : 'auto',
        })},[_vm._t("default")],2),_vm._v(" "),(!_vm.hideActions)?_c('div',{staticClass:"filter-dropdown__actions"},[_c('ThemeLink',{attrs:{"aria-label":_vm.translations.resetFilterAria},on:{"click":_vm.onClearClick}},[_c('span',[_vm._v(_vm._s(_vm.translations.resetFilter))])]),_vm._v(" "),_c('VfButton',{staticClass:"vf-button--primary vf-button--extra-small",attrs:{"aria-label":_vm.translations.applyButtonAria,"disabled":_vm.disabledButton},on:{"click":_vm.onApplyClick}},[_c('span',[_vm._v(_vm._s(_vm.disabledButton
              ? _vm.translations.applyButtonDisabled
              : _vm.translations.applyButton))])])],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
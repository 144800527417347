export const orderDetails = {
  backToHistoryLinkCustomClass: 'large-only',
  getDirectionButtonClass:
    'vf-button--medium vf-button--mobile-full vf-order-summary__direction-button',
  cancelledItemsLabelClass: '',
  returnButtonSize: 'md',
  defaultStoreName: 'THE NORTH FACE STORE',
  notificationType: 'success',
  showNotificationClose: true,
  showNotificationText: true,
  showShippingAddress: true,
  showCostsExpanded: true,
  showDividerAfterDate: true,
  showDeliveryAsText: true,
  showEddAndTrackingInSeparateLines: true,
  showItemPrice: true,
  showGiftOption: true,
  showFreeInsteadZero: true,
  hideMultipleTrackingLabels: true,
  appendColorCode: true,
  showSmallDescription: false,
  showTrackingBeforeProducts: false,
  showFlatTrackOrderText: false,
  groupItemsFromShipment: false,
  addressFormat: [
    '{{addressLine1}} {{addressLine2}} {{addressLine3}}',
    '{{city}} {{province}} {{postalCode}} {{country}}',
  ],
  pickupPersonInfoFormat: ['{{firstName}} {{lastName}}', '{{email}}'],
  pickupAddressFormat: [
    '{{addressLine1}}',
    '{{addressLine2}}',
    '{{city}}, {{province}} {{zipCode}}',
  ],
  personInfoFormat: [
    '{{firstName}} {{lastName}}',
    '{{phoneNumber}}',
    '{{email}}',
  ],
  inlineMobileQtyPrice: false,
  imageWidth: 110,
  imageHeight: 130,
};

export const productSizeSelect = {
  headingModifier: 'subtitle-4',
  dividerModifier: 'divider-hidden',
  addVariantOptionToUrlQueryParams: true,
  showYourSizeUnavailableLink: false,
  showProductSizeGuideNearLabel: false,
  showSizeErrorIcon: true,
  minAttributeOptionsNumber: 1,
  hideComponentForOneSize: true,
  scrollToErrorOffset: 120,
  scrollToErrorOffsetWithTopStickyHeader: 150,
};

export const staticCart = {
  closeIconSize: 'xs',
  discountNotification: {
    notificationPersistent: true,
    notificationType: 'success',
  },
  promotionMessage: {
    promotions: [],
  },
  deliveryMethodSelector: {
    cancelButtonColor: 'primary',
  },
  panelCloseButtonSize: '1.5rem',
  promoCode: {},
  orderSummarySidebar: {},
  miniCart: {
    cartIcon: 'bag',
    buttons: {
      cart: 'primary',
    },
  },
  productCard: {
    imageWidth: 110,
    imageHeight: 139,
    removeIcon: 'trash',
    removeIconSize: '24px',
  },
  expressCheckout: {
    isPayPalVisible: true,
    isApplePayVisible: true,
  },
  savedForLaterProducts: {
    paginationCounter: 6,
  },
  showInformationalSidebar: true,
  giftOptionEnabled: true,
  hasGiftTopNotification: false,
  giftAppliedMessageIcon: {
    icon: 'error',
    color: 'red-primary',
  },
  upsell: {
    color: 'primary',
    buttonSize: 'xs',
  },
};

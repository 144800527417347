export const categoryTag = {
  showCategoryBelowImage: true,
};

export const articlesGrid = {
  imageSizes: {
    small: {
      width: '300',
      height: '300',
    },
    medium: {
      width: '320',
      height: '320',
    },
    large: {
      width: '440',
      height: '440',
    },
  },
};

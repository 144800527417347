export const productCard = {
  showQuickShopTile: true,
  productCardModifier:
    'vf-product-card vf-product-card--tnf-card vf-product-card--modifier',
  isPlP: true,
  showImagesWithShotType: true,
  showBadgesByColorSwatch: true,
  maxSliderImages: 3,
  imageAspectRatio: 2150 / 2500,
  updateColorOnBack: false,
  stylePropName: 'styleId',
  quickShopAnimationDuration: 300,
};

export const checkoutPaymentInfo = {
  addNewCardButtonComponent: 'ThemeButton',
  addNewCardButtonVariant: 'border',
  addNewCardButtonClass: 'payment-methods__add-new-card-button',
  paymentMethodOptionClass: 'col small-12 medium-4 large-3',
  hiddenLabels: ['paypal', 'klarna'],
  showMethodVariantsSeparately: true,
  showCardLogo: true,
  showCardAddress: true,
  skipHandlingNonCreditCardChange: true,
  isApplePayDefault: true,
};






















































import { ref, inject } from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';

export default {
  name: 'VfCheckoutHeader',
  props: {
    tooltipContent: String,
    isClearSessionVisible: Boolean,
  },
  setup() {
    const {
      root: {
        $env: { WEBSITE_NAME },
      },
    } = useRootInstance();

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      showTooltip: ref(false),
      isCoreRedesignEnabled,
      WEBSITE_NAME,
    };
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSelectList',
  directives: { focus },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: '',
    },
  },
});

export const socialNative = {
  default: {
    placementLayout: [2, 3, 1, 3, 3, 1, 3, 2],
    homepage_count: 9,
    clp_count: 6,
    gallery_count: 9,
  },
  mobile: {
    placementLayout: [1],
    homepage_count: 5,
    clp_count: 5,
    gallery_count: 6,
  },
};

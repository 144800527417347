const defaultStoreData = (locale) => ({
  country: {
    title: 'DEALERS / STORE LOCATIONS',
    meta_data: {
      title: 'Locations/Store Directory',
      description: 'Directory of locations. Find a local store near you.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
  },
  state: {
    cities: 'Cities',
    city_title: 'Dealers / Store Locations in {state_name}',
    meta_data: {
      title: 'The North Face Stores and Dealers in {state} ({state_code})',
      description:
        'Find the nearest The North Face store or the authorized dealer in {state} to shop for official TNF apparel and footwear today.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
    splitDealers: true,
    titleVfStore: 'The North Face Store Locations',
    titleDealerStore: 'The North Face Authorized Dealers',
  },
  city: {
    title: 'Dealers / Store Locations in {city_state}',
    meta_data: {
      title: 'The North Face Stores and Dealers in {city}, {state_name}',
      description:
        'Find a The North Face store near you in {city}, {state_name} to shop for official TNF apparel and footwear today.',
      keywords: '',
      main_breadcrumb_url_name: 'Select a state',
    },
    formatLink: '{name} - {address1}, {state}, {postalcode}',
    storeOrder: 'name, address1, postalcode',
    splitDealers: true,
    titleVfStore: 'The North Face Stores and Outlets',
    titleDealerStore: 'The North Face Authorized Dealers',
  },
  store: {
    isStoreContentExtended: true,
    store_map_title:
      'STORE DETAILS / STORE LOCATION IN {city}, {province} | {id}',
    store_meta_title: '{name} in {city}, {province}, {postalcode}',
    store_meta_description:
      'Shop at {name} in {city}, {province} for great deals on official TNF outerwear, backpacks, footwear, and more.',
    store_picture_alt: 'Storefront of {name} in {city}, {province}',
    appData: {
      appkey: '266588F2-0EFD-11EB-B231-64499CAB76FA',
      iframe_url: 'https://locations.thenorthface.com/mapwidget.html',
      containedIn: 'Mall/Shopping Center Column Header',
      smsButtonText: 'Send to Mobile',
      emailButtonText: 'Send to Email',
      directionText: 'Get directions',
      openings_text: 'Hours',
      share_text: 'SHARE',
      info_text: 'Info',
      pictureText: 'STORE PHOTO',
      mapUrl: 'https://www.google.com/maps/dir',
      about_title: 'About Us',
      fb_title: 'Follow Us on Facebook',
      services_title: 'Additional Services',
      pickup_title: 'In-Store Pickup:',
      buy_collect_text: 'Buy online & collect in store.',
      return_text:
        "<p>Return, exchange or claim your warranty for items bought in The North Face store only. Check brand official <a href='/" +
        locale +
        "/help/returns-policy' target='_blank'>return and warranty policies</a> for more info.</p>",
      products_title: 'Available Products',
      special_hours: 'Special Hours',
      temp_closed_info: 'This store is temporarily closed until ',
      reopen_date_info: 'The store is opening ',
      find_store_title: 'Find a Store',
    },
    store: {
      carries: {
        footwear: 'Footwear',
        apparel: 'Apparel',
        equipment: 'Equipment',
        youth: 'Youth',
      },
    },
    logo:
      'https://static.thenorthface.com/static/com/ui/www/images/logos/logo.hash-47df7a386f8905e81dd2111ade04c9fe.svg',
    image: '',
    daysOfWeek: [
      {
        dayOfWeek: 'Monday',
        key: 'm',
      },
      {
        dayOfWeek: 'Tuesday',
        key: 't',
      },
      {
        dayOfWeek: 'Wednesday',
        key: 'w',
      },
      {
        dayOfWeek: 'Thursday',
        key: 'thu',
      },
      {
        dayOfWeek: 'Friday',
        key: 'f',
      },
      {
        dayOfWeek: 'Saturday',
        key: 'sa',
      },
      {
        dayOfWeek: 'Sunday',
        key: 'su',
      },
    ],
  },
});

export const storeData = {
  'en-us': defaultStoreData('en-us'),
  'en-ca': defaultStoreData('en-ca'),
  'fr-ca': {
    ...defaultStoreData,
    country: {
      title: 'CONCESSIONNAIRES / EMPLACEMENTS DES MAGASINS',
      meta_data: {
        title: 'Emplacements / Répertoire des magasins',
        description:
          'Annuaire des lieux. Trouvez un magasin local près de chez vous.',
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
    },
    state: {
      cities: 'Villes',
      city_title:
        'Concessionnaires / Emplacements des magasins en {state_name}',
      meta_data: {
        title: 'Magasins et revendeurs The North Face à {state} ({state_code})',
        description:
          "Trouvez le magasin The North Face le plus proche ou le revendeur agréé à {state} pour acheter des vêtements et des chaussures officiels TNF dès aujourd'hui.",
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
      splitDealers: true,
      titleVfStore: 'Emplacements des magasins The North Face',
      titleDealerStore: 'Revendeurs agréés The North Face',
    },
    city: {
      title: 'Concessionnaires / Emplacements des magasins en {city_state}',
      meta_data: {
        title: 'Magasins et revendeurs The North Face à {city}, {state_name}',
        description:
          "Trouvez un magasin The North Face près de chez vous à {city}, {state_name} pour acheter des vêtements et des chaussures officiels TNF dès aujourd'hui.",
        keywords: '',
        main_breadcrumb_url_name: 'Sélectionner un état',
      },
      formatLink: '{name} - {address1}, {state}, {postalcode}',
      storeOrder: 'name, address1, postalcode',
      splitDealers: true,
      titleVfStore: 'Magasins et points de vente The North Face',
      titleDealerStore: 'Revendeurs agréés The North Face',
    },
    store: {
      isStoreContentExtended: true,
      store_map_title:
        'STORE DETAILS / STORE LOCATION IN {city}, {province} | {id}',
      store_meta_title: '{name} à {city}, {province}, {postalcode}',
      store_meta_description:
        'Magasinez chez The North Face au {name} à {city}, {province} pour profiter d’incroyables aubaines sur les manteaux, sacs à dos, chaussures et autres articles TNF officiels.',
      store_picture_alt: 'Vitrine de {name} à {city}, {province}',
      appData: {
        appkey: '266588F2-0EFD-11EB-B231-64499CAB76FA',
        iframe_url: 'https://locations.thenorthface.com/mapwidget.html',
        containedIn: 'Mall/Shopping Center Column Header',
        smsButtonText: 'Envoyer un sms',
        emailButtonText: 'Envoyer un email',
        directionText: 'Obtenir des directions',
        openings_text: 'Heures',
        share_text: 'PARTAGER',
        info_text: 'Info',
        pictureText: 'PHOTO DU MAGASIN',
        mapUrl: 'https://www.google.com/maps/dir',
        about_title: 'À Propos',
        fb_title: 'Suis nous sur Facebook',
        services_title: 'Services Supplémentaires',
        pickup_title: 'Ramassage en Magasin:',
        buy_collect_text: 'Achat en ligne et ramassage en magasin.',
        return_text:
          "<p>Retournez, échangez ou faites valoir votre garantie pour les articles achetés dans le magasin de The North Face uniquement. Consultez <a href='/fr-ca/help/returns-policy' target='_blank'>les politiques officielles de retour et de garantie</a> de la marque pour plus d'informations.</p>",
        products_title: 'Produits disponibles',
        special_hours: 'Heures Spéciales',
        temp_closed_info: "Ce magasin est temporairement fermé jusqu'à ",
        reopen_date_info: 'Le magasin ouvre ',
        find_store_title: 'Points de Vente',
      },
      store: {
        carries: {
          footwear: 'Chaussures',
          apparel: 'Vêtements',
          equipment: 'Équipement',
          youth: 'Jeunesse',
        },
      },
      logo:
        'https://static.thenorthface.com/static/com/ui/www/images/logos/logo.hash-47df7a386f8905e81dd2111ade04c9fe.svg',
      image: '',
      daysOfWeek: [
        {
          dayOfWeek: 'Lundi',
          key: 'm',
        },
        {
          dayOfWeek: 'Mardi',
          key: 't',
        },
        {
          dayOfWeek: 'Mercredi',
          key: 'w',
        },
        {
          dayOfWeek: 'Jeudi',
          key: 'thu',
        },
        {
          dayOfWeek: 'Vendredi',
          key: 'f',
        },
        {
          dayOfWeek: 'Samedi',
          key: 'sa',
        },
        {
          dayOfWeek: 'Dimanche',
          key: 'su',
        },
      ],
    },
  },
};

export const productsGrid = {
  gridSizes: [
    {
      name: 'expanded',
      icon: 'grid_active_2_column',
      selectedIcon: 'grid_active_2_column',
      showOnMobile: true,
      grid: {
        small: 12,
        medium: 6,
        large: 6,
      },
      product: {
        small: {
          badges: true,
          scrollableImages: true,
          showSwiperIcons: true,
          colors: 3,
        },
        medium: {
          badges: true,
          scrollableImages: true,
          showSwiperIcons: true,
          colors: 13,
        },
        large: {
          badges: true,
          scrollableImages: true,
          showSwiperIcons: true,
          colors: 13,
        },
      },
    },
    {
      name: 'normal',
      icon: 'grid_active_4_column',
      selectedIcon: 'grid_active_4_column',
      showOnMobile: true,
      grid: {
        small: 6,
        medium: 3,
        large: 3,
      },
      product: {
        small: {
          badges: false,
          scrollableImages: false,
          showSwiperIcons: false,
          colors: 3,
        },
        medium: {
          badges: true,
          scrollableImages: true,
          showSwiperIcons: true,
          colors: 7,
        },
        large: {
          badges: true,
          scrollableImages: true,
          showSwiperIcons: true,
          colors: 7,
        },
      },
    },
    {
      name: 'compact',
      icon: 'grid_active_6_column',
      selectedIcon: 'grid_active_6_column',
      showOnMobile: false,
      grid: {
        small: 6,
        medium: 2,
        large: 2,
      },
      product: {
        small: {
          badges: false,
          scrollableImages: false,
          showSwiperIcons: false,
          colors: 3,
        },
        medium: {
          badges: false,
          scrollableImages: true,
          showSwiperIcons: false,
          colors: 4,
        },
        large: {
          badges: false,
          scrollableImages: true,
          showSwiperIcons: false,
          colors: 4,
        },
      },
    },
  ],
  defaultGridSize: 'normal',
  imageSizes: {
    small: {
      width: 555,
      height: 650,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
    medium: {
      width: 735,
      height: 856,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
    large: {
      width: 1005,
      height: 1175,
      quality: 50,
      resMode: 'sharp2',
      opSum: '0.9,1.0,8,0',
    },
  },
  loadPrevious: {
    isLoadPreviousEnabled: true,
    loadPreviousAdditionalButtonClasses: '',
  },
};

export const loyaltyOrderRegister = {
  hideAccruedPoints: false,
  buttonVariant: 'loyalty',
  logo: {
    icon: 'xplr_logo',
    width: 117,
    height: 47,
    color: 'white',
  },
};

export const cartProductList = {
  imageWidth: 110,
  imageHeight: 130,
  tableColspan: 6,
  showErrorNotification: true,
  showSuccessMessagePerRow: true,
  sortProductsByFlashErrors: false,
  notificationClass:
    'vf-notification--center vf-notification--no-box-shadow vf-notification--no-icon vf-notification--with-gray-background vf-notification--big',
  storeNearFieldMaxLength: 256,
  showStaticTextInsteadOfShippingLabel: true,
  productAvailabilityModalCancelButtonClass:
    'vf-button--tertiary vf-shipment-store-modal__btn',
  showStsInfoInProductAvailabilityModal: false,
  showGeneralFlashNotificationOnCart: false,
  showFavoritesNotificationOnCart: true,
  showSavedForLaterProductsSeparatorOnMobile: true,
  savedForLaterProductsHeaderTag: 'th',
  productIdPropertyName: 'masterId2',
};

export const SimplifiedFooter = {
  logo: {
    mcAfee: {
      width: 81,
      height: 32,
    },
    bef: {
      width: 32,
      height: 32,
    },
  },
};

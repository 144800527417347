export const productActionButtons = {
  actionButtonSize: 'sm',
  actionIconSize: '32px',
  wrapperPaddings: {
    default: '0',
  },
  findInAStoreButtonColor: 'primary',
  scrollToErrorOffset: 120,
  scrollToErrorOffsetWithTopStickyHeader: 150,
};

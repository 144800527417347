export const productAddToCart = {
  largeScreenClass: 'vf-button--fullWidth',
  closeMiniCartDelay: 5000,
  scrollToErrorOffset: 120,
  scrollToErrorOffsetWithTopStickyHeader: 150,
  enableLoading: true,
  enableSuccessLabel: true,
  pdpUrlWithAttributes: true,
  isSignInToBuyAtVariantLevel: true,
  modal: {
    loggedIn: 'loyaltyEnrollment',
    notLoggedIn: 'signInToBuy',
  },
  quickshopScrollToTopAfterAddToCart: false,
};

import { DirectiveBinding } from 'vue/types/options';
import { Element } from './../types';

export const clickOutside = {
  bind(el: Element, binding: DirectiveBinding): void {
    const closeHandler = binding.value;
    el._outsideClickHandler = function (event) {
      if (!el.contains(event.target as Node)) {
        event.stopPropagation();
        closeHandler();
      }
    };
    document.addEventListener('mouseup', el._outsideClickHandler);
    document.addEventListener('touchstart', el._outsideClickHandler);
  },
  unbind(el: Element): void {
    document.removeEventListener('mouseup', el._outsideClickHandler);
    document.removeEventListener('touchstart', el._outsideClickHandler);
    el._outsideClickHandler = null;
  },
};

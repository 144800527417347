export const emailPreferences = {
  headingModifier: 'title-5',
  emailModifier: 'title-5',
  controlsClass: 'vf-email-preferences__controls-modify',
  // Buttons for Variant A
  submitButton: {
    color: null,
    variant: null,
    size: null,
    class: 'vf-email-preferences__button--underline',
  },
  cancelButton: {
    color: null,
    variant: null,
    size: null,
    class: 'vf-email-preferences__button--underline',
  },
  ctaButton: {
    color: 'primary',
    variant: 'border',
    size: 'md',
    class: 'vf-email-preferences__button',
    context: null,
  },
  // Button for Variant B
  saveButton: {
    color: 'primary',
    size: 'lg',
    class: 'vf-email-preferences__submit__btn',
  },
  showEmail: true,
};

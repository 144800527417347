





































import { defineComponent, PropType } from '@vue/composition-api';
import { Sizes } from '@vf/api-contract/src/ui/optionsSwatch';
import { QuickShopTileErrorMessage } from '@vf/api-contract';

export default defineComponent({
  name: 'VfQuickShopTileOptionsSlot',
  props: {
    value: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    options: {
      type: Array as PropType<Sizes>,
      default: () => [],
    },
    availableVariants: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    titleGroup: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: Object as PropType<QuickShopTileErrorMessage>,
    },
    role: {
      type: String,
      default: 'size',
    },
  },
});

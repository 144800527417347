export const staticCheckout = {
  footer: {
    brandLogo: {
      width: 130,
      height: 60,
      label: 'The North Face',
    },
    safetyLogos: [
      {
        iconName: 'safety-mcafee',
        url: 'https://www.mcafee.com/',
        width: 81,
        height: 32,
        alt: 'McAfee',
        ariaLabel: 'McAfee',
      },
      {
        iconName: 'safety-bef',
        url: 'https://www.b-e-f.org/',
        width: 32,
        height: 32,
        alt: 'Bonneville Environmental Foundation',
        ariaLabel: 'Bonneville Environmental Foundation',
      },
    ],
  },
  header: {
    clearSession: {
      link: '/account/loyalty/create-account',
    },
  },
  giftCard: {
    maxGiftCards: 2,
  },
  productCard: {
    imageWidth: 110,
    imageHeight: 139,
  },
  payPal: {
    color: 'gold',
    shape: 'pill',
    height: 55,
    borderRadius: 28,
  },
  orderSuccess: {
    showCloseIcon: true,
  },
  showBuyInStoreInfo: true,
  showStoreEmployeeInfo: false,
};


import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  functional: true,
  render(h, { props, data, listeners, children }) {
    return h(
      'UiLink',
      {
        ...data,
        props,
        on: listeners,
      },
      children
    );
  },
});

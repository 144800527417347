export const giftOption = {
  giftBoxImage:
    'https://assets.thenorthface.com/image/upload/q_auto,f_auto/v922339/Gift-Box-with-GC',
  giftBoxImageWidth: 326,
  giftBoxImageHeight: 240,
  itemImageWidth: 66,
  itemImageHeight: 78,
  editableItemImageWidth: 110,
  editableItemImageHeight: 129,
  maxMessageCharacters: 200,
};





























































import { defineComponent, inject, PropType } from '@vue/composition-api';

interface Logotype {
  iconName: string;
  url: string;
  width: number;
  height: number;
  alt: string;
  ariaLabel: string;
}

export default defineComponent({
  name: 'VfCheckoutFooter',
  props: {
    brandLogo: Object as PropType<{
      width: number;
      height: number;
      label: string;
    }>,
    safetyLogos: {
      type: Array as PropType<Logotype[]>,
      default: () => [],
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@vue/composition-api';
import {
  createCheckerboard,
  createGradient,
  hasHighBrightness,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfFilterColor',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    rel: {
      type: String,
      default: undefined,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    fill: {
      type: Array,
      default: () => [],
    },
    prevent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const select = () => {
      emit('click-select');
    };
    const remove = () => {
      emit('click-remove');
    };

    const isCheckerboard = computed(() =>
      props.value?.toLowerCase().includes('checkerboard')
    );

    const background = computed(() => {
      if (!props.fill.length) return '';

      const value = isCheckerboard.value
        ? (props.active
            ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), '
            : '') + createCheckerboard(props.fill)
        : createGradient(props.fill);

      return `--checkbox-background: ${value}`;
    });

    const iconColors = computed(() => {
      const isBrightBackground =
        !props.fill.length ||
        (props.fill.find(hasHighBrightness) && !isCheckerboard.value);

      return isBrightBackground
        ? { check: 'var(--c-gray-10)', close: 'var(--c-gray-40)' }
        : { check: 'white', close: 'white' };
    });

    const clickAction = () => {
      if (props.disabled) return;

      if (props.link && !props.prevent) {
        props.active ? select() : remove();
      } else {
        props.active ? remove() : select();
      }
    };

    return {
      remove,
      select,
      background,
      clickAction,
      iconColors,
    };
  },
});

export const findInStore = {
  defaultDistance: 25,
  useLocationClass: 'full-width',
  searchButtonClass: 'full-width adapt-underline',
  findInStoreComponent: 'ThemeLink',
  findInStoreComponentColor: 'primary',
  leftColumnLargeSize: 5,
  showStsMessageIfProductIsAvailable: false,
  imageWidth: 65,
  imageHeight: 75,
};





































import { defineComponent, PropType } from '@vue/composition-api';
import { ProductCrossSellItem } from '@vf/api-client';
import useRootInstance from '../../theme/shared/useRootInstance';
import { getUpSellPriceFormatted } from '../../composables/src/useUpsell/helpers';

export default defineComponent({
  name: 'VfCrossSellItem',
  props: {
    product: {
      type: Object as PropType<ProductCrossSellItem>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const showRegularPriceAfter = !!root.$themeConfig.productPrice
      ?.showRegularPriceAfter;

    const { regularPrice, specialPrice, priceRange } = getUpSellPriceFormatted(
      props.product.price,
      root.$formatPrice
    );

    const image = root.$mediaUrlGenerator({
      pid: props.product.id,
      productName: props.product.name,
      colorCode: props.product.colorCode,
    });

    return {
      image,
      priceRange,
      regularPrice,
      showRegularPriceAfter,
      specialPrice,
    };
  },
});

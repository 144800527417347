//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ref,
  watch,
  defineComponent,
  nextTick,
  inject,
} from '@vue/composition-api';
import { clickOutside } from '@vf/shared/src/utils/directives';
export default defineComponent({
  name: 'VfFilterDropdown',
  directives: { clickOutside },
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
    transition: {
      type: String,
      default: 'fade',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    flow: {
      type: String,
      default: 'column',
      validator: (flow) => ['row', 'column'].includes(flow),
    },
    disabledButton: {
      type: Boolean,
      default: true,
    },
    reversedButtonColor: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    colNumber: {
      type: Number,
      default: 4,
    },
    maxHeight: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const refFilterContainer = ref(null);
    const refFilterWrapper = ref(null);
    const wrapperRightPosition = ref(false);

    // TODO: Remove in GLOBAL15-62475
    const isFiltersUiUpdateEnabled = inject('isFiltersUiUpdateEnabled');

    watch(
      () => props.opened,
      (value) => {
        isOpen.value = value;
      }
    );

    const open = async () => {
      if (!isOpen.value) {
        isOpen.value = true;
        emit('change:opened', true);
        await nextTick();
        if (refFilterContainer.value) {
          // reset scroll top on open dropdown
          refFilterContainer.value.scrollTop = 0;
        }
        // anchor right position if content go over the screen
        if (
          refFilterWrapper.value?.getBoundingClientRect().right >
          window.innerWidth
        ) {
          wrapperRightPosition.value = true;
        }
      }
    };
    const close = () => {
      if (isOpen.value) {
        isOpen.value = false;
        emit('change:opened', false);
      }
    };
    const moveFocus = (keyCode) => {
      // allows for left/up/right/down arrows to work like tabbing, only for filter items
      keyCode === 'ArrowRight' || keyCode === 'ArrowDown'
        ? document.activeElement.nextElementSibling?.focus()
        : document.activeElement.previousElementSibling?.focus();
    };

    const toggle = () => {
      if (isOpen.value) close();
      else open();
    };

    const onClearClick = () => {
      emit('clear');
      close();
    };
    const onApplyClick = () => {
      emit('apply');
      close();
    };

    let anticipatingFocusEvent = false;
    const toggleButton = ref(null);

    const onKeyDown = (e) => {
      const arrowKeys = ['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'];

      // 1.
      if (e.code === 'Tab') anticipatingFocusEvent = true;
      else if (
        props.hideActions &&
        ((e.code === 'Enter' && e.target !== toggleButton.value?.$el) ||
          e.code === 'Space')
      )
        onApplyClick();
      else if (e.code === 'Escape') close();
      else if (e.target.tagName === 'A' && arrowKeys.includes(e.code)) {
        e.preventDefault();
        moveFocus(e.code);
      }

      // skip first focus out event
      setTimeout(() => {
        // skip focus out timeout
        setTimeout(() => {
          // 4.
          anticipatingFocusEvent = false;
        });
      });
    };

    let isFocusIn = false;
    const onFocusIn = () => {
      // 2.5.
      isFocusIn = true;
    };

    const onFocusOut = () => {
      // 2.
      isFocusIn = false;
      setTimeout(() => {
        // 3.
        if (anticipatingFocusEvent && !isFocusIn) close();
      });
    };

    return {
      isFiltersUiUpdateEnabled,
      isOpen,
      open,
      close,
      toggle,
      onApplyClick,
      onClearClick,
      toggleButton,
      onKeyDown,
      onFocusIn,
      onFocusOut,
      refFilterContainer,
      refFilterWrapper,
      wrapperRightPosition,
    };
  },
});

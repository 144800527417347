import { CheckoutSidebarConfigType } from '@vf/shared/src/theme/types/CheckoutSidebar';

export const checkoutSidebar: Partial<CheckoutSidebarConfigType> = {
  ctaButton: {
    class: '',
    color: 'primary',
    size: 'md',
    context: null,
  },
  shareMyCartButton: {
    class: '',
    color: 'secondary',
    size: 'md',
    context: null,
  },
  orderSummaryClass: 'vf-text',
  applyPromoCodeBtnClass: 'vf-button--small',
  promocodeAccordionClass: 'vf-accordion',
  promocodeAccordionStrongHeader: false,
  dividers: {
    default: {
      orderSummaryTop: true,
      orderSummaryBottom: true,
      editCartLinkBottom: true,
      taxTop: true,
      taxBottom: true,
    },
    cart: {
      orderSummaryTop: true,
      orderSummaryBottom: true,
    },
  },
  showShippingTimeAndLabelOneLine: true,
  hidePaypalForEmployee: false,
  applePayButtonVariant: 'primary',
  mergeNarvarAndPickupInfo: true,
  isKlarnaAllowedForMixedOrder: false,
  hideInvalidCoupons: true,
};

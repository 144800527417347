var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.appliedFilters.length)?_c('div',{staticClass:"applied-filters",class:{
    'applied-filters--redesign': _vm.isFiltersUiUpdateEnabled,
    'applied-filters--redesign-catalog': _vm.isVansPlpRedesignEnabled,
  }},[(_vm.isFiltersUiUpdateEnabled)?_c('VfCollapsibleChips',{staticClass:"applied-filters__column",attrs:{"observe":_vm.filters,"light-version":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isCollapsible = ref.isCollapsible;
  var toggleCollapse = ref.toggleCollapse;
return [_vm._l((_vm.filters),function(item){return _c('VfFacet',{key:item.value,staticClass:"applied-filters__tile",attrs:{"value":item.text,"tiled":true,"color":item.colors,"data-cm-metadata":_vm.$attrs['data-cm-metadata'],"aria-label":_vm.translations.resetAria},on:{"click-select":function($event){return _vm.$emit('reset', item)}}})}),_vm._v(" "),(_vm.showSeeAll && isCollapsible)?_c('VfFacet',{staticClass:"applied-filters__tile applied-filters__tile--action applied-filters__tile--show-all",attrs:{"value":_vm.translations.seeLessFilters,"icon":"minus","show-icon":_vm.showExpandIcon,"aria-label":_vm.translations.seeLessFiltersAria,"tiled":true},on:{"click-select":toggleCollapse}}):_vm._e()]}},{key:"cta",fn:function(ref){
  var isCollapsible = ref.isCollapsible;
  var isCollapsed = ref.isCollapsed;
  var toggleCollapse = ref.toggleCollapse;
return [(_vm.hasCustomResetButtonSlot)?[_vm._t("reset-button")]:_vm._e(),_vm._v(" "),_c('VfFacet',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSeeAll && isCollapsible),expression:"showSeeAll && isCollapsible"}],staticClass:"applied-filters__tile applied-filters__tile--action applied-filters__tile--show-all",class:{ 'applied-filters__tile--show-all--hide': !isCollapsed },attrs:{"value":_vm.translations.seeMoreFilters,"icon":"plus","show-icon":_vm.showExpandIcon,"aria-label":_vm.translations.seeMoreFiltersAria,"tiled":true},on:{"click-select":toggleCollapse}}),_vm._v(" "),(_vm.showSeeAll && isCollapsible && _vm.showDivider)?_c('div',{staticClass:"applied-filters__divider applied-filters__divider--redesign large-only"}):_vm._e(),_vm._v(" "),(_vm.showClearAllFacet && _vm.appliedFilters.length > 1)?_c('VfButton',{staticClass:"applied-filters__reset vf-button--text",on:{"click":function($event){return _vm.$emit('reset-all')}}},[_vm._v("\n        "+_vm._s(_vm.translations.resetFilter)+"\n      ")]):_vm._e()]}}],null,true)}):[_c('div',{staticClass:"applied-filters__column",class:{ 'applied-filters__column--full-width': !_vm.showReset }},[_vm._l((_vm.filters),function(item){return _c('VfFacet',{key:item.value,staticClass:"applied-filters__tile",class:{
          'applied-filters__tile--redesign-catalog': _vm.isVansPlpRedesignEnabled,
        },attrs:{"value":item.text,"tiled":true,"color":item.colors,"data-cm-metadata":_vm.$attrs['data-cm-metadata'],"aria-label":_vm.translations.resetAria},on:{"click-select":function($event){return _vm.$emit('reset', item)}}})}),_vm._v(" "),(_vm.isVansPlpRedesignEnabled)?_c('div',{staticClass:"applied-filters__divider--redesign-catalog small-only"}):_vm._e(),_vm._v(" "),(_vm.showDivider)?_c('div',{staticClass:"applied-filters__divider large-only"}):_vm._e(),_vm._v(" "),(_vm.showSeeAll && _vm.appliedFilters.length > _vm.minFiltersToDisplay)?_c('VfFacet',{staticClass:"applied-filters__tile applied-filters__tile--action applied-filters__tile--show-all",attrs:{"value":_vm.seeAll ? _vm.translations.seeLessFilters : _vm.translations.seeMoreFilters,"icon":_vm.seeAll ? 'minus' : 'plus',"show-icon":_vm.showExpandIcon,"aria-label":_vm.seeAll
            ? _vm.translations.seeLessFiltersAria
            : _vm.translations.seeMoreFiltersAria,"tiled":true},on:{"click-select":_vm.toggleSeeAll}}):_vm._e(),_vm._v(" "),(_vm.hasCustomResetButtonSlot)?[_vm._t("reset-button")]:_vm._e(),_vm._v(" "),(_vm.showClearAllFacet && _vm.appliedFilters.length > 1)?_c('VfFacet',{staticClass:"applied-filters__tile applied-filters__tile--action large-only",attrs:{"value":_vm.translations.resetFilter,"aria-label":_vm.translations.resetFilterAria,"tiled":true},on:{"click-select":function($event){return _vm.$emit('reset-all')}}}):_vm._e()],2),_vm._v(" "),(_vm.showClearAllFacet && _vm.appliedFilters.length > 1)?_c('div',{staticClass:"applied-filters__column applied-filters__column-right small-only"},[_c('VfFacet',{staticClass:"applied-filters__tile applied-filters__tile--action",attrs:{"value":_vm.translations.resetFilter,"aria-label":_vm.translations.resetFilterAria,"tiled":true},on:{"click-select":function($event){return _vm.$emit('reset-all')}}})],1):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }



































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'VfQuickShopTileButtons',
  props: {
    to: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    addToCartButtonText: {
      type: String,
      default: '',
    },
    signInToBuyButtonText: {
      type: String,
      default: '',
    },
    viewDetailsText: {
      type: String,
      default: '',
    },
    gridClassSuffix: {
      type: String,
      default: '',
    },
    isSignInToBuy: {
      type: Boolean,
      default: false,
    },
    isMonetate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show-details', 'add-to-cart', 'sign-in-to-buy'],
});

/**
 * Check if color is light or dark
 * @param colorCode hex code of color
 * @category Utils
 * @returns true if color is light, false if color is dark
 */

export const hasHighBrightness = (colorCode: string): boolean => {
  if (!colorCode) return false;

  // Convert the colorCode to lowercase and use regex to expand 3-character shorthand to 6-character hex
  const hexCode = colorCode.toLowerCase().replace(/^([0-9a-f]){3}$/, '$&$&');

  // Perceived brightness of red, green, and blue
  const brightnesses = [0.299, 0.587, 0.114];

  // Match two characters at a time for the color channels (R, G, B)
  const matches = hexCode.match(/.{1,2}/g);

  // Check if match is successful, handle null case
  if (!matches) return false;

  const [r, g, b] = matches.map(
    (code, i) => Number.parseInt(code, 16) * brightnesses[i]
  );

  // Compute luminance as a value between 0 and 1, values above 0.5 indicate low contrast
  return (r + g + b) / 255 > 0.5;
};
